module.exports = {
  recordingBackendUrl: process.env.REACT_APP_RECORDING_BACKEND_URL,
  backendURL: process.env.REACT_APP_BACKEND_URL || "wss://webrtc.jamkazam.com/",
  localWsServerUrl: process.env.REACT_APP_JAMKAZAM_WS_SERVER || "ws://localhost:3040",
  turnServerUrl: process.env.REACT_APP_TURN_SERVER_URL || "coturn.jamkazam.com:3478",
  options: {
    p2p: new URLSearchParams(window.location.search).get("p2p"),
    admin: new URLSearchParams(window.location.search).get("admin") || false,
    audiooff: new URLSearchParams(window.location.search).get("audiooff") || false,
    token: new URLSearchParams(window.location.search).get("token") || null,
    canvas: new URLSearchParams(window.location.search).get("canvas") || false,
    session_creator: new URLSearchParams(window.location.search).get("session_creator") || false,
    dontuseturn: new URLSearchParams(window.location.search).get("dontuseturn"),
    adaptive: new URLSearchParams(window.location.search).get("adaptive"),
    simulcast: new URLSearchParams(window.location.search).get("simulcastoff") ? false : true,
    maxCameras: 2,
    p2pThreshold: 4
  },
  peerToBitrateMapping: {
    "1": 3,
    "2": 2.3,
    "3": 1.7,
    "4": 1.5,
    "5": 1.2,
    "6": 1,
    "7": 0.7,
    "8": 0.6,
    "9": 0.5,
    "10": 0.5
  },
  aspectRatios: {
    "0": { height: 180, width: 320 },
    "1": { height: 360 * 1.5, width: 640 * 1.5 },
    "2": { height: 720, width: 1280 },
    "3": { height: 1280, width: 1920 },
  }
}
