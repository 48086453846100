import { IconButton, makeStyles } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import StopIcon from '@material-ui/icons/Stop';
import React, { useEffect, useState } from 'react';
const useStyles = makeStyles((theme) => ({
  icon: {
    color: "red",
    fontSize: 40
  },
}))

const audioContext = new AudioContext();
const audiosIncluded = {};
const dest = audioContext.createMediaStreamDestination();
export const RecordCloud = ({ localAudioStreams, audioStreams }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [recorded, setRecorded] = useState(null);
  const [stream, setStream] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    audioStreams.map(({ assignedId, stream }) => {
      if (!audiosIncluded[assignedId]) {
        audiosIncluded[assignedId] = true;
        let audioIn = audioContext.createMediaStreamSource(stream);
        audioIn.connect(dest);
      }
    })
    if (!audiosIncluded["local"] && localAudioStreams[0]) {
      audiosIncluded["local"] = true;
      let audioIn = audioContext.createMediaStreamSource(localAudioStreams[0].stream);
      audioIn.connect(dest);
    }
  }, [localAudioStreams, audioStreams])

  useEffect(() => {
    window.setTimeout(() => {
      startRecording();
    }, 5000)
  }, [])

  const startRecording = async () => {
    try {
      if (!window.recordingAdapter) {
        console.error("Recording adapter not loaded")
        return
      }
      const stream = window.maincanvas.captureStream(24);
      window.recordingAdapter.startRecording({ audioTrack: stream.getVideoTracks()[0], videoTrack: dest.stream.getAudioTracks()[0]})
      setStream(stream);
    } catch (e) {
      console.error('Exception while creating MediaRecorder:', e);
      return;
    }

    setIsRecording(true)
  }


  const stopRecording = () => {
    recorded.stopRecording(function (blob) {
      console.error(recorded.getBlob())
      const url = window.URL.createObjectURL(recorded.getBlob());
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'recording.webm';
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 100);
    });

    stream.getTracks().forEach(t => t.stop());
    setStream(null);
    setIsRecording(false);
  }

  return (
    <>
      {!isRecording && <IconButton size="small" style={{ background: "white" }}> <FiberManualRecordIcon className={classes.icon} onClick={startRecording} color="primary" variant="contained">
      </FiberManualRecordIcon></IconButton>}
      {isRecording && <IconButton size="small" style={{ background: "white" }}> <StopIcon className={classes.icon} onClick={stopRecording} color="primary" variant="contained">
      </StopIcon></IconButton>}
    </>
  )
}
